// Consumer
.consumer-container {
  .banker-page-products-summary {
    .collapse-content {
      padding-top: 25px;
    }
    .tabs {
      display: none;
    }
  }
}
