// Decision
.decision-container {
  padding: 30px 0;
  background: #fff;
  .decision-header {
    color: #515e75;
    display: flex;
    justify-content: center;
    margin-top: 15px;

    .svg-inline--fa {
      margin-right: 15px;

      > path {
        fill: #4d85eb;
      }
    }
  }
  .decision-desc {
    color: #515e75;
    line-height: 1.3em;
    display: flex;
    justify-content: center;
    margin: 30px 13%;
  }
  .decision-summary-container {
    display: flex;
    justify-content: center;
    margin: 30px 10%;
  }
  .decision-summary {
    margin: 30px 0 50px 0;
    list-style: none;
    padding: 0;
    display: flex;
    gap: 30px;
    flex-flow: wrap;
    justify-content: center;

    .decision-summary-item {
      display: flex;
      flex-direction: column;
      width: 250px;
      height: 300px;
      border: 1px solid;
      border-color: #e0e0e0;
      border-radius: 8px;
      padding: 30px 30px 30px 30px;
      position: relative;

      .decision-summary-item-product-badge {
        position: absolute;
        top: 8px;
        left: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffefd9;
        color: #ffab38;
        border: 1px solid #ffab38;
        border-radius: 24px;
        font-size: 11px;
        height: 24px;
        width: 110px;
      }

      .decision-summary-item-product-name {
        display: flex;
        justify-content: center;
        flex-direction: row;
        padding: 15px 0 0 0;
        height: 65px;
        color: #515e75 !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.3em;
      }
      .decision-summary-item-product-details {
        display: flex;
        flex-direction: column;
        padding: 15px 0;
        > div {
          display: grid;
          grid-template-columns: 1fr 1fr;
          > div {
            text-align: right;
            color: #515e75 !important;
            font-size: 13px;
            font-weight: 500;

            [style*="color:"] {
              color: #515e75 !important;
            }

            &:first-child {
              font-size: 11px;
              font-weight: bold;
              color: #afb7c4 !important;
              text-align: left;
            }
          }
        }
      }

      &.decision-summary-item-selected {
        background: #4d85eb;
        border-color: #3069d1;

        .decision-summary-item-product-name {
          color: #fff !important;
        }
        .decision-summary-item-product-details {
          > div {
            > div {
              color: #fff !important;

              [style*="color:"] {
                color: #fff !important;
              }

              &:first-child {
                color: #fff !important;
              }
            }
          }
        }
      }

      .decision-summary-item-product-selection {
        display: flex;
        justify-content: center;
        padding: 15px 0 0 0;

        button {
          height: 35px;
          width: 100%;
          font-size: 16px;
          font-weight: normal;
          background: #fff;
          border-color: #0078d6;
          color: #0078d6;

          &:hover {
            cursor: default;
          }
        }
      }

      &:not(.decision-summary-item-selected) {
        .decision-summary-item-product-selection {
          button {
            &:hover {
              cursor: pointer;
              background: #0078d6;
              color: #fff;
            }
          }
        }
      }
    }
  }

  .e-carousel
    .e-carousel-indicators
    .e-indicator-bars
    .e-indicator-bar
    .e-indicator
    div {
    border: 1px solid #ccc;
  }
  .e-carousel .e-carousel-navigators .e-previous .e-btn:not(:disabled),
  .e-carousel .e-carousel-navigators .e-next .e-btn:not(:disabled),
  .e-carousel .e-carousel-navigators .e-play-pause .e-btn:not(:disabled) {
    background-color: #0078d6;
  }
}
