.app-logo {
  height: 42.667px;
  box-sizing: border-box;
}

.flyout-drawer-content {
  overflow-y: scroll !important;

  .flyout-drawer-item-row {
    @apply px-5 py-3;
    border-right-width: 1px;
    border-bottom-width: 1px;
  }

  .flyout-drawer-item-row:last-child {
    border-bottom-width: 0;
  }

  .flyout-drawer-item-title {
    color: #515e75;
    font-size: 14px;
    font-family: Proxima Nova;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

  .flyout-drawer-item-value {
    color: #334056;
    font-size: 16px;
    font-family: Proxima Nova;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
}

// DaisyUI component overrides
.tabs-boxed {
  .tab {
    text-decoration: none !important;
  }

  .tab-override {
    background-color: #4d85eb !important;

    h5 {
      color: white !important;
      font-weight: normal;
    }
  }
}

// TYPOGRAPHY CLASSES
.body1 {
  font-family: "Proxima Nova";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.body1-m {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.body2 {
  font-family: "Proxima Nova";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}

.poweredby {
  color: #afb7c4;
  font-size: 18px;
  font-family: Proxima Nova;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.36px;
  word-wrap: break-word;
}

.relativityxlink {
  color: rgba(77, 133, 235, 0.6);
  font-size: 18px;
  font-family: Proxima Nova;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.36px;
  word-wrap: break-word;
}

.compare-products-heading {
  color: #515e75;
  font-size: 16px;
  font-family: Proxima Nova;
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;
}

// Solution Confirmation
.solution-confirmation-container {
  padding: 30px 0;
  .solution-confirmation-header {
    color: #515e75;
    display: flex;
    justify-content: center;

    .svg-inline--fa {
      margin-right: 15px;

      > path {
        fill: #4d85eb;
      }
    }
  }
  .solution-confirmation-desc {
    color: #515e75;
    line-height: 1.3em;
  }
  .solution-confirmation-summary {
    margin: 30px 0;
    list-style: none;
    padding: 0;

    .solution-confirmation-summary-item {
      display: grid;
      grid-template-columns: 300px 1fr 1fr 1fr 1fr;
      gap: 10px;
      grid-auto-flow: column dense;
      color: #515e75;
      border-top: 1px solid #e0e6ed;
      padding: 15px 0 0 0;

      &:first-child {
        border-top: 0;
        padding: 0;
      }

      > div > div {
        font-size: 14px;
        margin-bottom: 5px;
        font-weight: 500;
        color: #515e75;

        > span {
          color: #515e75 !important;

          &.subtle {
            font-weight: normal;
            color: #afb7c4 !important;
          }
        }

        &:first-child {
          font-size: 12px;
          line-height: 20px;
          white-space: nowrap;
          font-weight: 500;
          color: #afb7c4;
        }
      }

      .solution-confirmation-summary-item-product {
        > div {
          font-size: 12px;
          font-weight: normal;
          &:first-child {
            font-size: 14px;
            font-weight: bold;
            color: #515e75;
          }
        }
      }
    }
  }
}
