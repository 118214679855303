@font-face {
  font-family: "Proxima Nova";
  src: local("Proxima Nova"),
    url("../public/fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

html,
body {
  margin: 0;
  font-family: "Proxima Nova", sans-serif;
  background: #f1f2f6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
