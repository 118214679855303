.solution-item--container {
  &-header {
    color: #4d85eb;
    font-size: 18px;
    font-family: Proxima Nova;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.36px;
    word-wrap: break-word;
  }

  &-description {
    color: #515e75;
    font-size: 14px;
    font-family: Proxima Nova;
    font-weight: 400;
    line-height: 16px;
    word-wrap: break-word;
  }

  &-value-label {
    font-size: 16px;
    font-family: Proxima Nova;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16px;
    word-wrap: break-word;
  }

  &-select-button {
    font-size: 16px;
    font-family: Proxima Nova;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

  .collapse-title::after {
    top: 42px;
    color: #afb7c4;
  }
}

.metric-change__container {
  &-header {
    color: var(--gray, #afb7c4);
    font-family: "Proxima Nova";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.16px;
  }

  &-description {
    color: var(--dark-gray-1, #334056);
    font-family: "Proxima Nova";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  }

  &-value-label {
    color: var(--dark-gray-1, #334056);
    text-align: right;
    font-family: "Proxima Nova";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.36px;
  }

  &-select-button {
    font-size: 16px;
    font-family: Proxima Nova;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

  .collapse-title::after {
    right: -2px;
    top: 42px;
    color: #afb7c4;
  }
}
